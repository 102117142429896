import React from "react";
import styled from "styled-components";
const ButtonStyle = styled.button`
  background-color: red;
  font-size: 15px;
  color: white;
  font-family: bold;
  padding: 8px 6px;
  border: 0 !important;
  border-radius: 5px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
`;
const CloseStyle = styled.button`
  font-size: 15px;
  border: 0 !important;
  color: #000;
  background: none;
`;
const Buttons = ({ label, onClick, fullWidth }) => {
  return (
    <>
      <ButtonStyle onClick={onClick} fullWidth={fullWidth}>
        {label}
      </ButtonStyle>
    </>
  );
};
const Close = ({ label, onClick }) => {
  return (
    <>
      <CloseStyle onClick={onClick}>{label}</CloseStyle>
    </>
  );
};

export { Buttons, Close };
