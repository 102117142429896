import React from "react";
import MetaTags from "./components/MetaTags";
import AppBar from "./components/AppBar";
import { Container, Row, Col } from "react-bootstrap";
import { PiMapPinSimpleAreaBold } from "react-icons/pi";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import Mark from "./components/Mark";
const ContactUs = () => {
  return (
    <div>
      <MetaTags
        title="Contact Us - Hemnath Crackers - Whole sale Crackers"
        type="website"
        siteName="hemnathcrackers.com"
        url="https://hemnathcrackers.com/contactus"
        keywords="Contact Us | Hemnath Crackers "
        description="Contact for wholesale Crackers and Retail Crackers"
        revisitAfter="10 Days"
      />
      <AppBar />
      <img
        src={require("../assets/images/banner_three.jpg")}
        className="img-fluid w-100"
        alt="product name"
      />
      <Container className="padding">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="py-3">
            <div className="bold  text-center"> Contact Us</div>
          </Col>
          <Col lg="6" className="py-3">
            <Row>
              <Col lg="12" md="12" className="py-3">
                <div className="box">
                  <div className="box-icon">
                    <PiMapPinSimpleAreaBold size={40} color={"#000"} />
                  </div>
                  <div className="regular">
                    Sankarankovil Main Road, Vembakottai-626131
                  </div>
                </div>
              </Col>
              <Col lg="12" className="py-3">
                <div className="box">
                  <div className="box-icon">
                    <BiPhoneCall size={40} color={"#000"} />
                  </div>
                  <div>
                    <div>+91 9655176029</div>
                    <div>+91 7010706745</div>
                  </div>
                </div>
              </Col>
              <Col lg="12" className="py-3">
                <div className="box">
                  <div className="box-icon">
                    <MdOutlineAlternateEmail size={40} color={"#000"} />
                  </div>
                  <div className="pb-4">
                    <div>hemnathcrackers@gmail.com</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg="6" md="6" xs="12" className="py-5">
            <>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3937.0675837854465!2d77.76332889012872!3d9.327279574479022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06a532c103ffff%3A0xd4e1696750037136!2sSattur%20-%20Sivakasi%20-%20Kalugumalai%20Rd%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1727963448012!5m2!1sen!2sin"
                width="100%"
                height="600"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </>
          </Col>
        </Row>
      </Container>

      <>
        <Mark />
      </>
    </div>
  );
};

export default ContactUs;
